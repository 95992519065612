.form {
  .coverageSection {
    overflow-x: auto;

    section {
      margin-bottom: 1rem;
    }

    .formRow {
      display: flex;
      justify-content: space-between;
      padding: 0 1px;

      .companyName {
        width: 70%;
      }

      .coverageType {
        width: 25%
      }

      .coverageTypeInput {
        height: 2.1rem !important;
      }

      .coverageTypeSelect {
        margin-top: -2px;
      }

      .benefitTable {
        border: 1px solid #ddd;
        border-spacing: 0;
        border-collapse: collapse;
        display: table;
        margin: 0.75rem 0rem;

        thead th, tbody td {
          border: 1px solid #ddd;
          padding: 5px;
        }

        tbody td {
          padding-top: 2rem;
        }

        thead th {
          border-bottom-width: 2px;
          text-align: left;
          font-weight: 700;
        }

        .cellLabelRequired:after {
          content: '*';
          color: #d3222a;
          margin-left: 4px;
          position: relative;
          top: -5px;
        }

        .monthlyBenefit {
          width: 7.5rem;
        }

        .benefitPeriod, .catMonthlyBenefit {
          width: 7.12rem;
        }

        .employerPaid, .replaceCoverage {
          width: 10rem;
        }
      }

    .policyNumber {
        width: 100%;
        margin-right: 2rem;
      }

      .noBoldLabel {
        font-weight: 400;
      }
    }

    .additionalDetails {
      margin-bottom: 4rem;
      padding: 0 1px;
    }
  }
}
