.page {
  background-color: #f5f7fa;
  padding: 1rem 1rem 1rem 0rem;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  .pageTitle {
    margin-top: 1.25rem;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
  }

  .pageContent {
    display: flex;
    font-size: 0.875rem;
    gap: 2rem;
    margin-top: 1rem;
    margin-left: 2rem;

    .tableContentBody {
      background-color: white;
      box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
      height: fit-content;
      width: 71%;
      @media all and (max-width: 91rem) {
        width: 95%;
      }

      .applicantsTableContainer {
        .titleContainer {
          display: flex;
        }
        .activeButton {
          margin-left: auto;
          margin-right: 14px;
          margin-top: 14px;
        }
        .tableTitle {
          margin-top: 1rem;
          margin-left: 1rem;
          font-size: 1.25rem;
          font-weight: 500;
          letter-spacing: 0.005rem;
        }

        .applicantsTable {
          :global(.Table-search-input) {
            width: 98%;
            margin-left: 0.5rem;
          }

          :global(.SearchBox-icon) {
            top: 4px;
          }
        }

        .actionButton,
        .actionButton:hover,
        .actionButton:focus:not(.focus-visible) {
          color: black;
        }

        .actionButton:disabled {
          background-color: transparent;
        }
      }

      .modalButtons {
        display: flex;
        justify-content: flex-end;

        .modalButton {
          width: 5rem;
        }
      }
    }

    .reportContent {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 22rem;

      .reportContentItem {
        background-color: white;
        box-shadow:
          0 5px 5px -3px rgba(0, 0, 0, 0.2),
          0 8px 10px 1px rgba(0, 0, 0, 0.14),
          0 3px 14px 2px rgba(0, 0, 0, 0.12);

        .header {
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.01em;
          line-height: 1.2em;
          padding: 1rem;
          margin-top: 0.5rem;
          color: rgba(0, 0, 0, 0.54);
          background-color: rgb(250, 250, 250);
        }

        .itemButtons {
          display: flex;
          flex-direction: column;

          .itemButton {
            padding-left: 1rem;

            .itemButtonContent {
              display: flex;
              gap: 1.5rem;
              align-items: center;

              .buttonText {
                text-align: left;
                padding: 0.5rem 0rem;
                letter-spacing: 0.01rem;
                overflow: hidden;
                text-overflow: ellipsis;
                color: rgba(0, 0, 0, 0.87);

                h3 {
                  font-size: 1rem;
                  font-weight: 400;
                  line-height: 1.2em;
                  margin: 0rem;
                  white-space: normal;
                }

                h4 {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 1.2em;
                  margin: 3px 0 1px;
                }

                p {
                  font-size: 14px;
                  font-weight: 500;
                  margin: 0rem;
                  line-height: 1.6em;
                  color: rgba(0, 0, 0, 0.54);
                }
              }
            }

            .itemButtonContent,
            .itemButtonContent:hover,
            .itemButtonContent:focus:not(.focus-visible) {
              color: black !important;
            }
          }
        }
      }
    }
  }
}
