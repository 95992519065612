.offersTable {
    border-spacing: 0;
    border-collapse: collapse;

    th {
        text-align: center;
        background-color: #d51e22;
        color: #fff;
        padding: 5px;
        border: 1px solid #000;
        border-bottom: 0px;
    }

    td {
        padding: 4px;
        text-align: left;
        border: 1px solid #000;
        padding-left: 10px;
        font-size: 14px;
    }
}

.tableHead {
    text-align: center;
    background-color: #d51e22;
    color: #fff;
    padding: 5px;
    border: 1px solid #000;
    border-bottom: 0px;
}

.continueButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
        button {
            margin-top: 10px !important;
            margin-bottom: 30px !important;
            margin-left: 20px !important;
        }
}

.policy {
    color: #337ab7;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    margin-top: 10px;
    font-weight: 400;
}

.header {
    background-color: #337ab7;
    font-size: 20px;
    letter-spacing: .005em;
    box-sizing: border-box;
    font-weight: 400;
    color: #fff;
    display: flex;

    .contenth1 {
        width: 100%;
        text-align: center;
    }

    button {
        color: #fff !important;
    }
}

.maxwidth {
    .MuiPaper-root-MuiDialog-paper {
        width: 1500px !important;
    }
}

div[role=tabpanel] {
    h3 {
        font-weight: 500 !important;
        font-size: 24px !important;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}