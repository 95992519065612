.hide {
	display: none;
}
.app {
	height: 100%;
}
.body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;
}
.container {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 71px 0rem 1rem 0rem;
}
.loadingContainer {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.messageContainer {
	align-items: center;
	display: flex;
    flex-flow: column wrap;
	flex-direction: column;
	width: 100%;
	margin: 71px 1rem 1rem 1rem;
	margin-top: 1rem;
	transition: all .5s ease;
}
.content {
	flex: 1 0 auto;
	align-self: center;
	width: 100%;
	position: relative;
}