.summary {
  font-size: 1rem;
  font-weight: 400;
  margin: 0rem 6rem;

  header {
    text-align: center;

    h3 {
      font-size: 1.5rem !important;
      font-weight: 500 !important;
      line-height: normal;
      margin-bottom: 10px;
    }

    p {
      font-size: 13px;
      font-style: italic;
    }
  }

  .mainContent {
    margin-bottom: 1rem;

    .sectionHeader {
      padding-bottom: 15px;
      padding-top: 30px;
    }

    table {
      border-spacing: 0;
      border-collapse: collapse;
    }

    .summaryTable {
      td:first-child {
        width: 340px;
        padding-right: 3rem;
      }

      td:last-child {
        width: 360px;
        max-width: 360px;
        overflow-wrap: break-word;
        word-wrap: break-word;
      }
    }

    .summaryTable, .existingCoverageSummaryTable {
      table, th, td {
        border-bottom: 1px solid black;
        padding: 5px;
      }

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }
    }
  }

  .submitButton {
    display: flex;
    flex-direction: row-reverse;
  }
}
