.page {
  background-color: white;
  padding: 1rem 4rem 2rem 3rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #333;
  margin: 1rem 5rem;

  header {
    text-align: center;

    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: normal;
    }
  }

  .formBody {
    .cellLabelRequired:after {
      content: '*';
      color: #d3222a;
      margin-left: 4px;
      position: relative;
      top: -5px;
    }

    .listContainer {
      width: 100%;
      margin: 0.5rem 0rem 0rem 0rem;
      border: solid 1px grey;

      .list {
        padding: 0;

        ul {
          padding: 0;
        }

        .listItem {
          border-bottom: 1px solid #ddd;
          padding: 10px;
        }
      }
    }

    .invalidList {
      border-color: #d3222a;
    }

    .reportIdsErrorMessage {
      font-weight: 400;
      font-size: 13px;
      color: #d3222a;
      padding-top: 4px;
      padding-left: 12px;
      cursor: text;
      transition: 0.15s ease-out all;
    }

    .employerPlaceholder {
      height: 94px;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .runButton {
      width: 5rem;
    }
  }
}