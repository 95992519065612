.enrollmentDate {
  :global(.DayPicker-Overlay) {
    position: relative !important;

    :global(.DatePickerOverlay-Wrapper) {
      position: relative;
      width: fit-content;
    }
  }
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
