.container {
    margin-top: 60px;
    max-width: 80% !important;
    .card {
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0;
        h3 {
            font-size: 1.5rem;
            font-weight: 500;
            margin-top: 20px;
            margin-bottom: 10px;
            line-height: normal;
        }
    }
}
.listContainer {
    height: 335px;
    width: 100% !important;
    margin: 0 !important;
    border: solid 1px grey;
    overflow-y: scroll;
}
.listItem {
    padding: 0 !important;
}
.list {
    border-bottom: 1px solid #ddd;
    padding: 10px;
}
.listError {
    color: #d3222a;
    padding-left: 12px;
    margin-top: 10px;
}
.cancelBtn {
    margin-right: 10px;
}