.electronicTransactionsForm {
  font-size: 1rem;
  font-weight: 400;
  margin: 0rem 6rem;

  header {
    text-align: center;

    h3 {
      font-size: 1.5rem !important;
      font-weight: 500 !important;
      line-height: normal;
    }
  }

  .formBody {
    margin-top: 2rem;

    p {
      margin-top: 1.5rem;
      margin-bottom: 3rem;
      padding-right: 0.5rem;
    }
  }

  .submitButton {
    display: flex;
    flex-direction: row-reverse;
  }
}
