.employerForm {
    width: 875px;
    margin: 0 auto;
    max-width: 946px;
    display: block;

    input:disabled {
        background-color: #c5c5c5 !important;
    }

    select:disabled {
        background-color: #c5c5c5 !important;
    }

    svg {
        margin-bottom: -5px;
    }
  
    input.nonVoltronField {
        width: 100%;
        -moz-min-width: 100%;
        -ms-min-width: 100%;
        -o-min-width: 100%;
        -webkit-min-width: 100%;
        min-width: 100%;
        -ms-border-radius: 3px;
        border-radius: 3px;
        color: #333;
        border: 1px solid #767676;
        box-sizing: border-box;
        height: 34px;
        padding-left: 12px;
        line-height: 1.5rem;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1rem;
        font-weight: 400;
    }

    .currencyInput {
        .error {
            border-color: #d3222a;
        }
    }
}