.Header{
    background-color: #ffffff;
    box-shadow: 0px 1px 9px #ccc;
    padding: 5px 10px;
    position: fixed;
    width: 100%;
    z-index:999;
    img{
        width: 160px;
    }
}
.floatRight{
    float: right;
}