.customeTab {
    button {
        font-size: 16px;
        letter-spacing: 1.5px;
    }

    button.MuiButtonBase-root-MuiTab-root.Mui-selected {
        color: #d3222a !important;
    }
}

.head {
    font-size: 24px !important;
    font-weight: 400;
    line-height: 32px !important;
    color: #000000de;
    margin-bottom: 10px;
}

.content {
    p {
        font-size: 14px;
        line-height: 1.42857143;
    }

    li {
        font-size: 14px;
        line-height: 1.42857143;
    }

    a {
        font-size: 14px;
        line-height: 1.42857143;
        text-decoration: underline;
        color: #595959;
        margin-bottom: 10px;
        display: block;
    }

    .continueButton{
        float: right;
        margin-top: 10px;
        margin-bottom: 30px;
    }
}