.healthInfoForm {
  font-size: 1rem;
  font-weight: 400;
  margin: 0rem 6rem;

  header {
    text-align: center;

    h3 {
      font-size: 1.5rem !important;
      font-weight: 500 !important;
      line-height: normal;
      margin-bottom: 1.5rem;
    }
  }

  .formBody {
    .select {
      margin-bottom: 1rem;
    }
  }

  .submitButton {
    display: flex;
    flex-direction: row-reverse;
  }
}
