.hamburger {
    margin-top: 10px;

    svg {
        font-size: 35px;
        color: #000;
    }
}

.menuNav {
    padding: 0 !important;
    color: #000 !important;
}

.menuItemLink {
    padding: 10px 22px;
    width: 100%;
}

.modalButton {
    display: flex;
    justify-content: flex-end;
}

.disabledHamburger {
    @extend .hamburger;

    svg {
        color: rgba(0, 0, 0, 0.26);
    }
}
