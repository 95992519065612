.inputLabel {
  font-size: 12px;
  color: #000;
  font-weight: normal;
  font-style: italic;
  display: block;
}

.innerHeader {
  h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 0;
  }

  h4 {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.innerHeader p {
  font-style: italic;
  font-size: 13px;
  text-align: center;
  margin-bottom: 10px;
}

.formLabel {
  font-size: 1rem;
  color: #333;
  font-weight: 400;
  display: block;
}

.formError.field {
  border-color: #fa787e !important;
}

.warning-message {
  color: red;
  padding: 0.5rem 0 0 0.5rem;
}

hr {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0;
  border-top-color: rgba(0, 0, 0, 0.12);
}

.checkbox {
  position: absolute;
  opacity: 0;
  z-index: 1;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border-color: rgba(0, 0, 0, 0.54);
    border-width: 2px;
    border-style: solid;
    border-radius: 2px;
    box-sizing: border-box;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before {
    background: rgba(255, 64, 129, 0.87);
    border: none;
  }

  &:disabled + label {
    color: rgba(0, 0, 0, 0.38);
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 12px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.m-auto {
  margin: auto;
}

h1 {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
}

.formLabelfomr {
  .formLabel {
    margin: 10px 0 8px 0;
  }
}

.MuiDialog-container {
  td {
    min-width: 300px;
  }
}

.Input--marginTopContainer {
  margin-top: 0 !important;
}

.DayPicker-Overlay {
  position: absolute !important;
}

.Input-regular {
  padding: 0px 11px !important;
  line-height: 1.5rem;
  border-radius: 3px !important;
}

.MuiContainer-maxWidthXl {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-0 {
  padding: 0;
  margin: 0 !important;
}

.MuiFormControlLabel-labelPlacementEnd {
  display: block !important;
  .Label-container {
    display: inline-block !important;
  }
}
span.MuiFormControlLabel-label {
  position: relative;
  top: -3px;
}
.columnLeft {
  margin-left: 60px !important;
}

.error-summary-table {
  td {
    border: 1px solid #dddddd;
    padding: 5px;
  }

  th {
    background-color: #dddddd;
    font-weight: bold;
    padding: 5px;
  }

  .error-summary-table-footer {
    background-color: #dddddd;
    font-style: italic;
    padding: 10px;
  }
}

.customvoltrontol {
  position: absolute !important;
  margin-left: 30px;
  margin-top: -5px;

  span {
    background: #236192;
    color: #fff;
    max-width: 220px;
    font-size: 12px;
    border: 1px solid #dadde9;
    line-height: 18px;
  }
}
.DatePickerOverlay-Wrapper {
  margin-top: 0px !important;
}

.mt-2 {
  margin-top: 10px;
}

.currencyinvalid::after {
  color: #d3222a;
  content: '*';
  margin-left: 4px;
  position: relative;
  top: -5px;
}

.currencyErrorMessage {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #d3222a !important;
  padding-top: 4px !important;
  padding-left: 12px !important;
  cursor: text !important;
  transition: 0.15s ease-out all !important;
}

#formDisabled {
  input:disabled {
    background-color: transparent !important;
  }
}
.adminDashboardPage {
  background-color: white;
  padding: 1rem 1rem 1rem 0rem;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  .pageTitle {
    margin-top: 1.25rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
  }

  .contentBody {
    display: flex;
    font-size: 0.875rem;

    .actions {
      flex-basis: 19%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1.5rem 0.5rem;

      .actionsTitle {
        font-size: 1.25rem;
        font-weight: 450;
        letter-spacing: 0.005rem;
        margin-bottom: 0.75rem;
      }

      .actionButton {
        margin: 0rem 0rem 0.75rem 0rem;
        width: 100%;
      }
    }

    .tableContainer {
      flex-basis: 76%;

      .dashboardTable .Table-header--filters .Table-search-input {
        width: 100%;
      }

      .dashboardTable .Table-header--filters .Table-search-input .SearchBox-icon {
        top: 4px;
      }

      .actionButton,
      .actionButton:hover,
      .actionButton:focus:not(.focus-visible) {
        color: black;
      }
    }

    .modalButtons {
      display: flex;
      justify-content: flex-end;

      .modalButton {
        width: 5rem;
      }
    }
  }
}

.adminEditPage {
  background-color: white;
  padding: 1rem 4rem 2rem 3rem;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 1rem 5rem;

  header {
    text-align: center;

    h3 {
      font-size: 1.5rem;
      font-weight: 500 !important;
      line-height: normal;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    p {
      font-size: 13px;
      font-style: italic;
    }
  }

  .formBody {
    .formRow {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}

.modalButtons {
  display: flex;
  justify-content: flex-end;

  .modalButton {
    width: 5rem;
  }
}
@media all and (min-width: 1rem) {
  .modal {
    background-color: hsla(0, 0%, 35%, .25);
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: visible;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all .2s;
  }

  .modalContainerInitialCenter {
    overflow: hidden;
    background-color: #fff;
    color: #333;
    position: fixed;
    z-index: -1;
    min-width: 320px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .2s, z-index .2s;
  }

  .modalIcon {
    box-sizing: border-box;
    height: 40px !important;
    width: 40px !important;
    padding: 8px;
  }

  .modalClose {
    position: absolute;
    right: 0;
    margin: 8px 8px 0 auto;
    background: none;
    border: none;
  }

  .modalClose:hover, .modalClose:focus:not(.focus-visible) {
    background-color: transparent;
  }

  .modalClose:focus {
    outline: 1px dotted;
  }

  .modalContainerCenter {
    z-index: 2;
    opacity: 1;
    box-shadow: 2px 2px 1px 1px #ccc;
  }

  .modalTitleContainer {
    min-height: 3rem;
    display: flex;
  }

  .modalTitle {
    display: block;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.25rem;
    width: 85%;
    margin: 1rem
  }

  .modalChildren {
    padding: 0 1rem 1.5rem 1rem;
    overflow: auto;
  }

  .modalContainerInitialCenter .ModalContentInitial {
    transition: all .2s;
    opacity: 0;
  }

  .modalContainerInitialCenter .ModalContentOpen {
    opacity: 1;
  }
  #modalContainer:focus{
    outline:none;
  }
}

@media all and (min-width: 26.563rem) {
  .modalContainerInitialCenter {
    max-height: calc(100% - 1rem);
    height: auto;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media all and (min-width: 64rem) {
  .modalChildren {
    padding: 0 1.5rem 1.5rem 1.5rem;
    width: calc(100% - 3rem);
  }

  .modalTitle {
    margin-left: 1.5rem;
  }

  .modalContainerInitialCenter {
    width: 33%;
  }
}
