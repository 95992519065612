.bg {
    background-color: #236192;
    text-align: center;
    padding: 4px 0px 0px 0px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 10px;
    height: 30px;
    position: sticky;
    bottom: 0;
    width: 100%;

    a {
        color: #fff !important;
        margin: 15px;
        font-size: 14px;
        line-height: 1.3;
        font-weight: 400;
    }

    a:hover {
        text-decoration: underline;
    }
}