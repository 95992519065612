.content {
	text-align: center;
	h3 {
		font-size: 16px;
		line-height: 34px;
		margin: 0;
		font-weight: 400;
	}

	h4 {
		margin: 0;
		line-height: 46px;
		color: rgba(0,0,0,0.54);
		background-color: rgb(250,250,250);
		font-size: 14px;
		font-weight: 500;
		letter-spacing: .01em;
	}

	p {
		font-size: 16px;
		line-height: 20px;
		margin-top: 0;
	}
}