.uploader {
  width: 100%;
}

.fileSelectedText {
  font-size: 1rem;
  color: #333;

  .fileRequired:after {
    content: '*';
    color: #d3222a;
    margin-left: 4px;
    position: relative;
    top: -5px;
  }

  .fileError {
    color: #d3222a;
    font-weight: 400;
    font-size: 13px;
    padding-left: 12px;
  }
}
