.mainForm {
  font-size: 1rem !important;
  font-weight: 400 !important;
  margin: 0rem 6rem;

  header {
    text-align: center;

    h3 {
      font-size: 1.5rem !important;
      font-weight: 500 !important;
      line-height: normal;
    }

    p {
      font-size: 13px;
      font-weight: 600;
      font-style: italic;
    }
  }

  .formBody {
    margin-bottom: 1rem;

    .currencyInput {    
      input {
        line-height: 1.5rem !important;
        font-family: 'Source Sans Pro', sans-serif !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
        color: #333;
        height: 30px !important;
        border: 1px solid #767676 !important;
        border-radius: 3px !important;
        padding-left: 12px;
      }

      input::placeholder {
        font-weight: 300 !important;
        color: #595959 !important;
      }

      .error {
        border-color: #d3222a !important;
      }

      .currencyErrorMessage {
        font-weight: 400 !important;
        font-size: 13px !important;
        color: #d3222a !important;
        padding-top: 4px !important;
        padding-left: 12px !important;
        cursor: text !important;
        transition: 0.15s ease-out all !important;
      }

      input:focus, input:active, input:hover {
        border: 1.25px solid #0758ac !important;
        outline: 1px solid #0758ac !important;
      }
    }
  }

  .submitButton {
    display: flex;
    flex-direction: row-reverse;
  }
}