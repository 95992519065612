.container {
    margin-top: 60px;
    max-width: 80% !important;
    .card {
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0;
        h2 {
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
        }
        .summaryTitle {
            font-size: 20px;
            font-weight: 500;
            letter-spacing: .005em;
            margin: 0;
        }
    }
}

.formLegend {
    color: #333;
    font-size: 1rem;
    margin-bottom: 8px;
}