// @import "../../node_modules/voltron/src/assets/css/variables.css";
// @import "../../node_modules/voltron/src/assets/css/voltron.css";

body {
  background-color: #ccc;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 14px;
}

h1 {
  margin-top: 0;
}

.Mui-selected {
  color: #d3222a;
}

.MuiTabs-indicator {
  background-color: #d3222a;
}

.MuiPaper-root-MuiDialog-paper {
  width: 1500px !important;
}

.globalloader {
  position: absolute;
  background: #80808096;
  width: 100%;
  left: 0;
  text-align: center;
  height: 100%;
  top: 0;
  z-index: 99999999;
  cursor: progress;

  p {
    width: fit-content;
    margin: auto;
    margin-top: calc(100vh - 60vh);
  }

  .loadertext {
    font-size: 24px;
    width: fit-content;
    margin: auto;
    margin-top: calc(100vh - 60vh);
    padding: 15px 20px;
    background-color: #333;
    color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  :hover {
    cursor: progress;
  }
}

.MuiMenu-list {
  li {
    a, span {
      color: rgba(0, 0, 0, 0.87);
      font-style: inherit;
      font-variant: inherit;
      font-family: inherit;
      font-weight: 300;
    }
  }

  li:nth-child(3) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  li:nth-child(6) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  li:nth-last-child(1) {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.paddingHorizontal {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiTabScrollButton-horizontal svg {
  width: 30px;
  height: 30px;
}

.MuiTab-root {
  max-width: fit-content !important;
}
