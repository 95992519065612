.submitForm {
  font-size: 1rem;
  font-weight: 400;
  margin: 0rem 6rem;

  h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  ul li, p {
    margin-right: 0.5rem;
  }

  .submitButton {
    display: flex;
    flex-direction: row-reverse;
  }
}
