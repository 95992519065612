@import "voltron/dist/assets/css/variables.css";
@import "voltron/dist/assets/css/voltron.css";

.monthlyCostNonTobacco {
    float: left;
    margin-left: 10px;
}
.monthlyCostTobacco {
    float: left;
    margin-left: 50px;
}