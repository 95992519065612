.additionalForms {
  font-size: 1rem;
  font-weight: 400;
  margin: 0rem 6rem;

  header {
    text-align: center;

    h3 {
      font-size: 1.5rem !important;
      font-weight: 500 !important;
      line-height: normal;
    }

    p {
      font-style: italic;
      font-size: 13px;
      font-weight: 600;
      margin: 0rem 1rem;
    }
  }

  .formBody {
    margin-top: 2rem;

    .formRow {
      display: flex;
      justify-content: space-between;

      .questionItem {
        width: 48%;
      }
    }

    p {
      margin-bottom: 2rem;
      padding-right: 0.5rem;
    }

    .checkboxDescription {
      margin-top: 1rem;
    }
  }

  .submitButton {
    display: flex;
    flex-direction: row-reverse;
  }
}
