.form {
  .questionsRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .questionItem {
      width: 48%;
    }

    .replaceAmountField {      
      .replaceAmountLabel {
        display: block;
        margin-bottom: 0.5rem;
        font-size: 1rem;
        font-weight: 400;
        color: #333;
      }
      
      .replaceAmountLabel:after {
        content: '*';
        color: #d3222a;
        margin-left: 4px;
        position: relative;
        top: -5px;
      }

      input {
        width: 98%;
      }
    }
  }

  .detailSection {
    margin-top: 1rem;
    margin-bottom: 2rem;

    .detailQuestionsHeader {
      font-weight: 700;
    }
  }
}