.summaryItem {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}
.summaryWrap {
    .details {
        p {
            font-size: 14px;
            line-height: 20px;
            margin-left: 5px;
            margin-bottom: 0;
            word-wrap: break-word;
        }
    }
}